(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-row-match-analyses/assets/javascripts/coupon-row-match-analyses.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-row-match-analyses/assets/javascripts/coupon-row-match-analyses.js');
"use strict";


const {
  useSelector
} = ReactRedux;
const {
  CouponRowMatchAnalysis
} = svs.components.tipsen.couponRowMatchAnalyses;
const {
  selectMatchAnalysIdsForSpecificDrawAndMatchAndEventNumber
} = svs.components.sportinfo.gameAnalysesRedux.selectors;
const {
  selectEngineDefinition
} = svs.components.tipsen.engine.selectors;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const CouponRowMatchAnalyses = _ref => {
  let {
    drawNumber,
    matchId,
    productId,
    eventNumber
  } = _ref;
  const matchAnalysesIds = useSelector(state => selectMatchAnalysIdsForSpecificDrawAndMatchAndEventNumber(state, productId, drawNumber, matchId, eventNumber));
  const currentEngine = useSelector(selectEngineDefinition);
  const isGoalCount = currentEngine.outcomes.eventTypeId === EventTypeId.GOAL_COUNT;

  if (!matchAnalysesIds || matchAnalysesIds.length === 0) {
    return React.createElement("div", {
      className: "".concat(isGoalCount ? 'coupon-row-match-analyses-fulltraff' : 'coupon-row-match-analyses')
    }, "F\xF6r tillf\xE4llet har vi ingen spelanalys");
  }
  return React.createElement("div", {
    className: "".concat(isGoalCount ? 'coupon-row-match-analyses-fulltraff' : 'coupon-row-match-analyses')
  }, matchAnalysesIds.map(analysisId => React.createElement(CouponRowMatchAnalysis, {
    analysisId: analysisId,
    isGoalCount: isGoalCount,
    key: analysisId
  })));
};
setGlobal('svs.components.tipsen.couponRowMatchAnalyses.CouponRowMatchAnalyses', CouponRowMatchAnalyses);

 })(window);