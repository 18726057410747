(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-row-match-analyses/assets/javascripts/coupon-row-match-analysis.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-row-match-analyses/assets/javascripts/coupon-row-match-analysis.js');
"use strict";


const {
  useSelector
} = ReactRedux;
const {
  selectMatchAnalysis,
  selectAuthorProfile
} = svs.components.sportinfo.gameAnalysesRedux.selectors;
const CouponRowMatchAnalysis = _ref => {
  let {
    analysisId,
    isGoalCount
  } = _ref;
  const matchAnalysis = useSelector(state => selectMatchAnalysis(state, analysisId));
  const authorProfile = useSelector(state => selectAuthorProfile(state, matchAnalysis === null || matchAnalysis === void 0 ? void 0 : matchAnalysis.authorId));
  return React.createElement("div", {
    className: "coupon-row-match-analysis"
  }, !isGoalCount && React.createElement("div", {
    className: "coupon-row-match-analysis-header"
  }, React.createElement("div", {
    className: "analysis-author"
  }, React.createElement("span", {
    className: "analysis-author f-medium"
  }, "Analys av", ' ', authorProfile.authorLongName, ":"))), React.createElement("div", {
    className: "coupon-row-match-analysis-body"
    ,
    dangerouslySetInnerHTML: {
      __html: matchAnalysis.body
    }
  }));
};
setGlobal('svs.components.tipsen.couponRowMatchAnalyses.CouponRowMatchAnalysis', CouponRowMatchAnalysis);

 })(window);